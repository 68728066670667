import * as configDefault from './config.default';

// Will be replaced by entrypoint.sh
// eslint-disable-next-line 
const env = 'development';
// eslint-disable-next-line 
const apiEndpoint = 'https://dtms-admin-backend.ubisen.com/api';

const config = {
  env: env.startsWith('$') ? configDefault.env : env,
  apiEndpoint: apiEndpoint.startsWith('$') ? configDefault.apiEndpoint : apiEndpoint,
};
export default config;
